import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrCompanyPrintersView',
  computed: {
    ...mapState('company', ['printers'])
  }
})
export default class GtrCompanyPrintersView extends Vue {
  data () {
    return {
      loading: false,
      table: {
        headers: [
          { text: 'Machine ID', align: 'start', sortable: true, value: 'machine_id' },
          { text: 'URL', value: 'admin_url' },
          { text: 'Kiosk URL', value: 'kiosk_url' },
          { text: 'Company', value: 'company.name' },
          { text: 'Event', value: 'event.name' }
        ],
        items: []
      },
      search: ''
    }
  }

  async mounted () {
    await this.$store.dispatch('company/fetchCompanyPrinters', this.$route.params.uuid)
  }

  @Watch('printers')
  onPrintersChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload
    }
  }
}
